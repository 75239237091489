import axios from '@/plugins/axios'

const END_POINT = '/api/v1/event_types'

function getEventTypes() {
  return axios.get(`${END_POINT}`)
    .then(response => response.data.items)
}

function createEventType(data) {
  return axios.post(`${END_POINT}`, data)
    .then(response => response.data.item)
}

function updateEventType(groupCode, data) {
  return axios.put(`${END_POINT}/${groupCode}`, data)
    .then(response => response.data)
}

function deleteEventType(groupCode) {
  return axios.delete(`${END_POINT}/${groupCode}`)
    .then(response => response.data)
}

export default {
  getEventTypes,
  createEventType,
  updateEventType,
  deleteEventType,
}

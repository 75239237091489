<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3" v-for="type of eventTypes" :key="type.key">
        <v-card>
          <v-card-title>{{ type.name }} </v-card-title>
          <v-card-text>
            <b>Name:</b> {{ type.name }}<br />
            <b>Code:</b> {{ type.code }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="onClickEdit(type)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card>
          <v-card-title>Neue Tourenart</v-card-title>
          <v-card-text class="text-center">
            <v-btn x-large outlined icon color="success" @click="onClickCreate">
              <v-icon x-large>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <edit-card
        :denseToolbar="true"
        @save="saveEventType"
        @abort="dialog = false"
        :canDelete="true"
        @delete="deleteEventType"
      >
        <template v-slot:toolbar>
          <v-app-bar-title>
            {{
              editMode === "NEW"
                ? "Neue Tourenart erstellen"
                : `${selectedEventType.name} bearbeiten.`
            }}
          </v-app-bar-title>
        </template>
        <template v-slot:content>
          <v-form v-model="formValid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Code"
                  v-model="selectedEventType.code"
                  required
                  :disabled="editMode !== 'NEW'"
                  :hint="
                    editMode === 'NEW'
                      ? 'Der Code einer Tourenart kann nach dem erstellen nicht mehr geändert werden!'
                      : 'Der Code einer Tourenart kann nicht geändert werden.'
                  "
                  :persistent-hint="editMode !== 'NEW'"
                  :rules="
                    editMode === 'NEW' ? [rules.required, rules.unique] : []
                  "
                />
                <v-text-field
                  label="Name"
                  v-model="selectedEventType.name"
                  required
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </edit-card>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import eventTypeService from '@/services/eventType'
import EditCard from '@/components/EditCard.vue'
import clone from 'lodash/clone'
import Confirm from '@/components/Confirm.vue'
import { createNotification } from '@/utils'

const EMPTY_EVENT_TYPE = {
  name: '',
  code: '',
}

export default {
  name: 'EventTypes',
  components: { EditCard, Confirm },
  props: {},
  data() {
    return {
      eventTypes: [],
      selectedEventType: clone(EMPTY_EVENT_TYPE),
      dialog: false,
      formValid: false,
      editMode: null,
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich',
        unique: (value) => !this.eventTypes.map(e => e.code).includes(value) || 'Eine Tourenart mit diesem Code existiert bereits.',
      },
    }
  },
  watch: {},
  mounted() {
    this.fetchEventTypes()
  },
  methods: {
    onClickCreate() {
      this.selectedEventType = clone(EMPTY_EVENT_TYPE)
      this.editMode = 'NEW'
      this.dialog = true
    },
    onClickEdit(eventType) {
      this.selectedEventType = clone(eventType)
      this.editMode = 'EDIT'
      this.dialog = true
    },
    fetchEventTypes() {
      return eventTypeService.getEventTypes().then(data => { this.eventTypes = data })
    },
    saveEventType() {
      let promise
      if (this.editMode === 'NEW') {
        promise = eventTypeService.createEventType(this.selectedEventType)
      } else {
        promise = eventTypeService.updateEventType(this.selectedEventType.code, this.selectedEventType)
      }
      promise.then(_ => this.fetchEventTypes())
        .then(_ => {
          this.dialog = false
          createNotification(`Änderungen an "${this.selectedEventType.name}" gespeichert.`, 'success')
        })
    },
    deleteEventType() {
      this.dialog = false
      this.$refs.confirm.open(
        'Tourenart löschen',
        `Bist du sicher dass du die Tourenart "${this.selectedEventType.name}" löschen möchtest? Touren dieser Art bleiben erhalten, jedoch wird für alle Touren die Art auf "Sonstiges / Nicht bekannt" gesetzt.`,
        { color: 'red', width: 350 })
        .then((confirm) => {
          if (confirm === true) {
            eventTypeService.deleteEventType(this.selectedEventType.code)
              .then(_ => this.fetchEventTypes())
              .then(_ => createNotification('Tourenart gelöscht.', 'success'))
          }
        })
    },

  },
}

</script>
